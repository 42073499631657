import { ActionEntryList, TextEntry } from "components";
import { useYaml } from "hooks";

const WhoWeAre = () => {
  const content = useYaml(
    process.env.PUBLIC_URL + "/assets/config/who-we-are.yaml"
  );
  return (
    <div>
      <div className="py-8"></div>
      <div className="container mx-auto mb-4">
        <h1 className="text-4xl">
          Bündnis Demokratie stärken Rielasingen-Worblingen-Arlen
        </h1>
      </div>
      {content && (
        <>
          <TextEntry>
            <h1>{content.title}</h1>
            <p>{content.description}</p>
          </TextEntry>
          <div className="py-8"></div>
          <ActionEntryList actionEntries={content.actions} />{" "}
        </>
      )}
    </div>
  );
};

export default WhoWeAre;
