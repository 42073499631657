const TextEntry = ({ children }) => {
  return (
    <div className="container mx-auto">
      <div className="text-dark">
        <h1 className="text-xl font-semibold mb-4">{children[0]}</h1>
        <p className="text-l">{children[1]}</p>
      </div>
    </div>
  );
};

export default TextEntry;
