import { TextEntry } from "components";

const Rules = () => {
  return (
    <div>
      <div className="py-8"></div>

      <TextEntry>
        <h1>Unsere Regeln</h1>
        <p>
          {" "}
          <a
            href={
              process.env.PUBLIC_URL +
              "/assets/documents/Buendnis_Interne_Regeln.pdf"
            }
            download="Buendnis_Interne_Regeln.pdf"
          >
            Hier können Sie die Regeln herunterladen
          </a>{" "}
        </p>
      </TextEntry>
      <div className="py-8"></div>

      <div className="py-8"></div>
    </div>
  );
};

export default Rules;
