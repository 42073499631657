import { useState } from "react";

const CollapsabelChildren = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="container mx-auto">
      <h1 className="text-xl font-semibold mb-4">
        {title}{" "}
        <span
          className="cursor-pointer hover:text-blue-500 transition-all"
          onClick={() => {
            setIsCollapsed((isCurrentlyCollapsed) => !isCurrentlyCollapsed);
          }}
          style={{
            display: "inline-block",
            transform: `rotate(${isCollapsed ? "" : "9"}0deg)`,
          }}
        >
          ►
        </span>
      </h1>
      <div
        className="transition-all"
        style={{ overflow: "hidden", height: isCollapsed ? "0px" : "initial" }}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsabelChildren;
