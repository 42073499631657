import { TextEntry } from "components";

const Join = () => {
  return (
    <div>
      <div className="py-8"></div>

      <TextEntry>
        <h1>Jetzt beitreten</h1>
        <p>
          {" "}
          Das Formular, um beizutreten, können Sie{" "}
          <a
            className="text-gray-600 hover:text-blue-500 transition-all"
            href={
              process.env.PUBLIC_URL + "/assets/documents/Buendnis_Beitritt.pdf"
            }
            download="Buendnis_Beitritt.pdf"
          >
            hier ↓
          </a>{" "}
          herunterladen.{" "}
        </p>
      </TextEntry>
      <div className="py-8"></div>
    </div>
  );
};

export default Join;
