import ActionEntry from "../action-entry/ActionEntry";

const ActionEntryList = ({ actionEntries }) => {
  return (
    <div className="container mx-auto">
      {actionEntries &&
        actionEntries.map((entry) => {
          return (
            <ActionEntry href={entry.link} download={entry.download}>
              {" "}
              {entry.text}{" "}
            </ActionEntry>
          );
        })}
    </div>
  );
};

export default ActionEntryList;
