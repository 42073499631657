import { useState } from "react";

const Impressum = () => {
  const [hide, setHide] = useState(true);

  setTimeout(() => setHide(false), 200);

  return (
    <div className="container mx-auto">
      <div className="py-8"></div>
      <h1 className="text-xl">Impressum</h1>
      <p>
        Dr. Steffen de Sombre <br />
        Talwiesenstraße 21
        <br />
        78239 Rielasingen-Worblingen
        <br />
        Tel.: 07731 {!hide && 801561 ^ 11111}
        <br />
        Mail: demokratie.rwa{!hide && "(at)"}web.de
        <br />
      </p>
    </div>
  );
};

export default Impressum;
