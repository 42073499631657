import { Outlet } from "react-router-dom";
import { Navbar, Footer } from "components";
import "./Layout.css";

const Layout = () => {
  return (
    <div className="flex flex-col h-screen justify-between">
      <Navbar></Navbar>

      <div className="mx-2 mb-auto">
        <Outlet />
      </div>

      <div className="mb-4"> </div>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
