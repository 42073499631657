import { TextEntry } from "components";

const Contact = () => {
  return (
    <div>
      <div className="py-8"></div>

      <TextEntry>
        <h1>Kontakt</h1>
        <p>
          {" "}
          Kontaktieren Sie uns per Mail unter{" "}
          <a
            className="text-gray-800 hover:text-blue-500 transition-all"
            href="mailto:demokratie.rwa@web.de"
          >
            demokratie.rwa@web.de
          </a>
        </p>
      </TextEntry>
      <div className="py-8"></div>

      <div className="py-8"></div>
    </div>
  );
};

export default Contact;
