import { ActionEntryList } from "components";

const NoPage = () => {
  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-6xl">404 Error</h1>
      <p className="text-xl">Die von Ihnen angrefagt Seite existiert nicht!</p>
      <p className="text-xl">
        <ActionEntryList
          actionEntries={[
            { link: "", text: "Hier gehts zurück zur Startseite" },
          ]}
        >
          {" "}
        </ActionEntryList>
      </p>
    </div>
  );
};

export default NoPage;
