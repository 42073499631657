import { Link } from "react-router-dom";

const ActionEntry = ({ href, download, children }) => {
  return (
    <div>
      {download ? (
        <a
          download={download}
          href={href}
          className="text-xl text-gray-800 hover:text-blue-500 transition-all"
        >
          <span>{children}</span> ↓
        </a>
      ) : (
        <Link
          to={href}
          className="text-xl text-gray-800 hover:text-blue-500 transition-all"
        >
          <span>{children}</span> →
        </Link>
      )}
    </div>
  );
};

export default ActionEntry;
