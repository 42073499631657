import { Link } from "react-router-dom";

const NavbarEntry = ({ href, children, className }) => {
  return (
    <Link
      to={href}
      className={`text-gray-800 hover:text-blue-500 transition-all ${className}`}
    >
      {children}
    </Link>
  );
};

export default NavbarEntry;
