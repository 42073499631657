import { useState, useEffect } from 'react';
import { parse } from 'yaml';

const useYaml = (filePath, defaultValue = {}) => {
    const [yaml, setYaml] = useState(defaultValue);

    useEffect(() => {
        fetch(filePath)
            .then((response) => response.text())
            .then((responseBody) => setYaml(parse(responseBody)));
    }, [filePath]);

    return yaml;
}

export default useYaml;