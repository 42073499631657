import { EventEntry, CollapsabelChildren } from "components";
import { useEffect, useState } from "react";
import { useYaml } from "hooks";

const convertGermanDateToMS = (germanDate) => {
  const splitDate = germanDate.date.split(".");
  return Date.parse(`${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`);
};

const Events = () => {
  const events = useYaml(
    process.env.PUBLIC_URL + "/assets/config/events.yaml",
    []
  );
  const [pastEvents, setPastEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);

  useEffect(() => {
    let tempCurrentEvents = [];
    let tempPastEvents = [];
    const currentDate = Date.now();
    events
      .sort((a, b) => convertGermanDateToMS(a) - convertGermanDateToMS(b))
      .forEach((element) => {
        if (convertGermanDateToMS(element) > currentDate) {
          tempCurrentEvents.push(element);
        } else {
          tempPastEvents.push(element);
        }
      });
    setCurrentEvents(tempCurrentEvents);
    setPastEvents(tempPastEvents);
  }, [events]);

  return (
    <div>
      <div className="py-8"></div>
      <CollapsabelChildren title={"Aktuelle Veranstaltungen"}>
        {currentEvents.map((event) => (
          <>
            <EventEntry
              startTime={event.startTime}
              date={event.date}
              location={event.location}
              title={event.title}
              endTime={event.endTime}
              description={event.description}
              material={event.material}
            />
            <div className="py-2"></div>
          </>
        ))}
      </CollapsabelChildren>
      <div className="py-4"></div>
      <CollapsabelChildren title={"Vergangene Veranstaltungen"}>
        {pastEvents.map((event) => (
          <>
            <EventEntry
              startTime={event.startTime}
              date={event.date}
              location={event.location}
              title={event.title}
              endTime={event.endTime}
              description={event.description}
              material={event.material}
            />
            <div className="py-2"></div>
          </>
        ))}
      </CollapsabelChildren>
      <div className="py-8"></div>
    </div>
  );
};

export default Events;
