import NavbarEntry from "../navbar-entry/NavbarEntry";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`sticky top-0`}
      style={{
        backgroundColor: `rgba(255,255,255,${
          Math.min(100, 50 + Math.round(scrollPosition / 2)) / 100
        })`,
      }}
    >
      <div className="container mx-auto py-4 flex">
        <div>
          <NavbarEntry className="mx-2 text-lg" href="/">
            Wer wir sind{" "}
          </NavbarEntry>{" "}
          {/*<NavbarEntry className="mx-2 text-lg" href="rules">
            Unsere Regeln{" "}
          </NavbarEntry>*/}
          <NavbarEntry className="mx-2 text-lg" href="events">
            Veranstaltungen
          </NavbarEntry>{" "}
          <NavbarEntry className="mx-2 text-lg" href="join">
            Beitreten
          </NavbarEntry>{" "}
          <NavbarEntry className="mx-2 text-lg" href="contact">
            Kontakt
          </NavbarEntry>{" "}
        </div>
        <div className="grow"></div>
        <div className="mx-2">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "logo512.png"}
              style={{ width: "2em" }}
              alt="logo"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
