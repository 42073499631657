import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer footer-center bg-white">
      <div className="w-full mx-auto md:flex md:items-center md:justify-between">
        <div className="container mx-auto py-4">
          <Link
            to="impressum"
            className="mx-2 text-gray-800 hover:text-blue-500 transition-all"
          >
            Impressum{" "}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
