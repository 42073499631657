import ICalendarLink from "react-icalendar-link";
import { useEffect } from "react";
import { useState } from "react";

// eslint-disable-next-line
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

const EventEntry = ({
  startTime,
  date,
  title,
  location,
  description,
  endTime,
  material,
}) => {
  const [calendarEvent, setCalendarEvent] = useState();
  const [eventString, setEventString] = useState();

  useEffect(() => {
    const startTimeString = startTime.split(":").join(":") + ":00";
    const dateString = date.split(".").reverse().join("-") + "T";

    const startDateTime = dateString + startTimeString;
    let endDateTime = new Date(startDateTime).addHours(2);

    if (endTime) {
      endDateTime = dateString + endTime.split(":").join(":") + ":00";
    }

    const eventData = {
      startTime: startDateTime,
      endTime: endDateTime,
      title: title,
      location: location,
      description: description ? description : "",
      url: window.location.href,
    };

    setCalendarEvent(eventData);
    setEventString(
      `${title} am ${date}, ${
        endTime ? `von ${startTime} bis ${endTime}` : `${startTime}`
      } Uhr - Ort: ${location}`
    );
  }, [title, date, description, endTime, startTime, location]);

  return (
    <div>
      <div className="flex">
        <ICalendarLink
          filename={title}
          style={{ whiteSpace: "pre-wrap" }}
          className="text-lg cursor-pointer hover:text-blue-500 transition-all"
          event={calendarEvent}
        >
          💾
        </ICalendarLink>

        <div
          className="text-lg"
          style={{ whiteSpace: "pre-wrap" }}
          onDoubleClick={() => {
            const sharableEvent = {
              title: title,
              text: eventString,
              url: window.location.href,
            };
            if (navigator.share && navigator.canShare(sharableEvent)) {
              navigator.share(sharableEvent);
            }
          }}
        >
          {" "}
          {eventString}
        </div>
      </div>
      {description ? <p> {description} </p> : ""}
      {material ?  <a
            className="text-gray-600 hover:text-blue-500 transition-all"
            href={
              process.env.PUBLIC_URL + "/assets/documents/events" + material.asset
            }
            download={material.asset}
          >
            {material.description} herunterladen ↓
          </a> : ""}
    </div>
  );
};

export default EventEntry;
